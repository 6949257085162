<template>
  <div class="edit-pay-module-box">

    <div class="cell-b">
      <selfCell title="背景圖">
        <selfUpload
          v-model="configs.bg"
          type="default"
          @change="setValue('bg')"
        />
      </selfCell>
    </div>

    <div class="cell-b">
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
        />
      </selfCell>
      <selfCell
        title="描述"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          type="textarea"
          rows="5"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        />
      </selfCell>
    </div>

    <div
      v-for="(item,index) in configs.items"
      :key="index"
      class="cell-b"
    >
      <el-button-group class="btn-group">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :disabled="index == 0"
          size="mini"
          @click="itemCheck(index,index-1)"
        ></el-button>
        <el-button
          type="success"
          icon="el-icon-download"
          :disabled="index == configs.items.length-1"
          size="mini"
          @click="itemCheck(index,index+1)"
        ></el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="configs.items.length == 1"
          @click="itemDel(index)"
        ></el-button>
        <el-button
          type="warning"
          icon="el-icon-plus"
          size="mini"
          @click="itemAdd(index)"
        ></el-button>
      </el-button-group>
      <selfCell title="圖標">
        <selfUpload
          v-model="item.logo"
          type="default"
          @change="setValue('items')"
          style="width:150px;"
        />
      </selfCell>
      <selfCell
        title="標題"
        :column="true"
      >
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="item[`${lItem.code=='HK'?'':lItem.code}name`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`items`)"
        />
      </selfCell>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editModuleMixin from "../../mixins/editModuleMixin"
export default {
  name: "edit-pay-module",
  mixins: [langEditMixin, editModuleMixin],
  methods: {
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
</style>